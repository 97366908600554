interface State {
  paths: string[]
}

export const useSsoRedirectStore = defineStore('ssoRedirect', {
  state: (): State => ({
    paths: []
  }),

  actions: {
    push(url: string) {
      this.paths.push(url)
    },
    pop() {
      return this.paths.pop()
    },
    clear() {
      this.paths.length = 0
    }
  },

  persist: { storage: persistedState.localStorage }
})
